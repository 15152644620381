import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";


export default function EnvironmentalProtectionPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "environmental-protection"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC regularly prepares EPP and ESC plans by summarizing the primary environmental sensitives and potential impacts as well as recommending site-specific mitigations to minimize project impacts.  Plans are well-written and concise to meet expectations of the regulator and client.
            </p>
        </ServicePage>
    )
}
